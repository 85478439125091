import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import {
  BackgroundImage,
  FallbackBackgroundImage,
} from "../components/Styles/BackgroundImage";
import fallbackImage from "../../static/fallback-image.jpg";
import { H1 } from "../components/Styles/Typography";
import styled from "styled-components";
import { Link } from "gatsby";

export default props => {
  return (
    <MainLayout location={props.location}>
      <Img className="container-fluid">
        <FallbackBackgroundImage
          style={{ backgroundImage: `url(${fallbackImage})` }}
        />
        <IntroBody>
          <H1>404 - Page not found</H1>
        </IntroBody>
      </Img>
      <Paragraph className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8 col-md-offset-2">
            The page you are looking for has been removed or relocated.
            <br />
            Try one of these links:
            <br />
            <Link to="/">Home</Link>
            <br />
            <Link to="/latest-news">Latest News</Link>
            <br />
            <Link to="/apply-for-funding">Apply for Funding</Link>
          </div>
        </div>
      </Paragraph>
    </MainLayout>
  );
};

const IntroBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  padding-top: 3rem;
`;

const Paragraph = styled.div`
  margin-bottom: 5.5rem;
  text-align: center;
`;

const Img = styled.div`
  height: 50vh;
  width: 100%;
  top: -100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
